.bg_lightGrey, .bg_lightGray {
  background-color: #f3f3f3;
}

.bg_mediumGrey, .bg_mediumGray {
  background-color: #4a4a4a;
}

.bg_darkTransparency {
  background-color: rgba($brand-darker-color,.9);
  padding: 40px;

  @include media($phone, max) {
    padding: 1em;
  }

}

.bg_01 {
  //background: rgb(0,123,168);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: radial-gradient(circle, rgba($brand-default-color,1) 50%, rgba($brand-darker-color,1) 100%);
}

// grey gradient
.bg_02 {
  background-image: linear-gradient(45deg, rgba(253,251,251,.9), rgba(235,237,238,.1));
}

.bg_03_tint {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
  linear-gradient( rgba(255,255,255,.4), rgba(255,255,255,.8), rgba(255,255,255,.9) ),
  url("../../assets/img/03.jpg")
}

.bg_04_tint {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(226,226,226,0.5), rgba(226,226,226,0.0)),url("../../assets/img/04.jpg");
}

.bg_06_tint {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
  linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ),
  url("../../assets/img/06.jpg")
}

.bg_07_tint {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
  linear-gradient( rgba(0,0,0,.1), rgba(0,0,0,.5) ),
  url("../../assets/img/07.jpg")
}

.bg_08_tint {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:
  linear-gradient( rgba($brand-default-color,.5), rgba($brand-secondary-color,.5) ),
  url("../../assets/img/08.jpg")
}

// drone imagery
.bg_10_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0.95), rgba(255,255,255,0.85), rgba(255, 255, 255, 0.75)),url("../../assets/img/010.jpg");
}

// drone imagery
/*.bg_10b {
  //background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(51,51,51,0), rgba(51,51,51,0), rgba(77,77,77,0)),url("../../assets/img/010b.jpg");
}*/

// coastal
.bg_DJI_0016 {
  //background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(51,51,51,0), rgba(51,51,51,0), rgba(77,77,77,0)),url("../../assets/img/DJI_0016.jpg");
}

// drone mapping
.bg_drone_mapping {
  //background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(51,51,51,0), rgba(51,51,51,0), rgba(77,77,77,0)),url("../../assets/img/drone-mapping.jpg");
}

// aerial houses
.i20200629_074823_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //background-image: radial-gradient(rgba(77, 77, 77, 1), rgba(77, 77, 77, .8), rgba(77, 77, 77, .6), transparent), url("../../assets/img/20200629-074823.jpg");
  background-image: linear-gradient(rgba(255, 255, 255, .7), rgba(255,255,255,.9), rgba(255,255,255,.9), rgba(255,255,255,0.4)),url("../../assets/img/20200629-074823.jpg");
}

// fence
.boundaryidentify_tint {
  //background-attachment: fixed;
  //background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0)),url("../../assets/img/DSC_2513.jpg");
}

.bg_torrenstitle {
  //background-attachment: fixed;
  //background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0)),url("../../assets/img/DSC_2554.jpg");
}

// rural fence
.i20200703_043126_tint {
  //background-attachment: fixed;
  //background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.2), rgba(255,255,255,.3)),url("../../assets/img/20200703-043126.jpg");
}

// house plan
.i20200704_061247_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,.0), rgba(255,255,255,.0), rgba(255,255,255,0.0)),url("../../assets/img/20200704-061247.jpg");
}

// detail contour survey
.bg_detail_contour_survey_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(255,255,255,.0), rgba(255,255,255,.0), rgba(255,255,255,0.0)),url("../../assets/img/detail-contour-survey.jpg");
}

// construction site
.i20200704_070238_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0,0,0,.0), rgba(0,0,0,.0)),url("../../assets/img/20200704-070238.jpg");
}

// engineering and arch
.enginandarc1 {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0,0,0,.0), rgba(0,0,0,.0)),url("../../assets/img/EngAndArch1.jpg");
}

// as-con scan
.i874585_tint {
  //background-attachment: fixed;
  background-position: right !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),url("../../assets/img/874585.jpg");
}

// HMT laser scan
.i8745522_tint {
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),url("../../assets/img/8745522.jpg");
}

// structural steel
.i245975_tint {
  background-image: linear-gradient(rgba(0,0,0,.0), rgba(0,0,0,.0)),url("../../assets/img/245975.jpg");
  //background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// keswick train station
.IMG_1297_tint {
  background-image: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1)),url("../../assets/img/IMG_1297.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// construction at night by Tom
.bg_construction {
  background-image: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1)),url("../../assets/img/construction_edit.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// port adelaide
.contactus_tint {
  background-image: linear-gradient(45deg, rgba(226,226,226,0.5),rgba(226,226,226,0.5),rgba(226,226,226,.5),rgba(226,226,226,.5)),url("../../assets/img/DSC_2565.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// adelaide night
.i20200706_075113_tint {
  background-image: linear-gradient(rgba(226,226,226,0.1),rgba(226,226,226,0.1)),url("../../assets/img/20200706-075113.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// about us side
.bg_aboutus_side {
  background-image: linear-gradient(rgba(226,226,226,0.1),rgba(226,226,226,0.1)),url("../../assets/img/DSC_2475.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// construction in france
.i20200718_051104_tint {
  background-image: linear-gradient(rgba(226,226,226,0.1),rgba(226,226,226,0.1)),url("../../assets/img/20200718-051104.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// 3d laser scanning
.bg_laser_scanning_tint {
  background-image: linear-gradient(rgba(226,226,226,0.1),rgba(226,226,226,0.1)),url("../../assets/img/3d-scanning.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// blue building
.i20200718_051613_tint {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("../../assets/img/20200718-051613.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// driveway
.i20200718_053641_tint {
  background-image: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url("../../assets/img/DSCF2943.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_11 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/svg/HomepageBG.svg);
}

.bg_12 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //background-image: url(../../assets/img/DJI_0013.jpg);
  background: radial-gradient(circle, rgba($brand-default-color,.2) 25%, rgba($brand-default-color,.1) 50%, rgba($default-dark-color,0) 100%), url(../../assets/img/DJI_0013.jpg);;
  //background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0,0,0,0.2), rgba(0, 0, 0, 0.2)),url("../../assets/img/DJI_0013.jpg");
}

.bg_icon {
  background-image: url(../../assets/svg/360Surveying_Icon.svg);
}

.bg_icon {
  background-image:
  linear-gradient( rgba(0,123,168,1),  rgba(0,123,168,.9), rgba(231,236,238,1) )
}

.bg_logo {
  position: relative;
  width: 100%;
  height: 200px;
}

.bg_logo:not(:last-child)::after {
  position: absolute;
  width: 2000px;
  bottom: 1px;
  left: calc(30% - 200px);
  background: url('../../assets/svg/360Surveying_Icon.svg') no-repeat;
  background-size: contain;
  content: "";
  z-index: 1;
  top: 10px;
  opacity: .3;
}

.bg_logo2 {
  position: relative;
  width: 100%;
  height: 200px;
}

.bg_logo2:not(:last-child)::after {
  position: absolute;
  width: 2000px;
  bottom: 250px;
  top: calc(5% - 500px);
  background: url('../../assets/svg/360Surveying_Icon.svg') no-repeat;
  background-size: contain;
  content: "";
  z-index: 1;
  opacity: .3;
}

.bg_logo3 {
  position: relative;
  width: 100%;
  height: 200px;
}

.bg_logo3:not(:last-child)::after {
  position: absolute;
  width: 2000px;
  bottom: 1px;
  right: calc(10% - 200px);
  background: url('../../assets/svg/360Surveying_Icon.svg') no-repeat;
  background-size: contain;
  content: "";
  z-index: 1;
  top: 200px;
  opacity: .3;
}
