// FONTS
$header-font: ('Kameron-Bold','Merriweather Sans', sans-serif);
$header-secondary-font: ('Kameron-Regular','Merriweather Sans', sans-serif);
$header-baseline: 0.1445;

$body-font: ('Nunito', sans-serif);
$body-baseline: 0.1445;

$body-font-size: 1em;

$line-height: 155%;

$bottom-text-spacing: $body-font-size;

$gh: 1rem;

//FONT WEIGHTS
$ultraLight:	200;
$light:			300;
$normal:		400;
$semiBold:		500;
$bold:			600;
$ultraBold: 	900;

//COLORS
//more here: colors.scss
$color-medium: #706d6e;
//$color-medium: #303030;
$default-button-color: #202020;
$default-dark-color: #303030;
$default-white-color: #fff;
$brand-default-color: #1c5379;
$brand-secondary-color: #007ba8;
$brand-darker-color: #0d324c;
$orange: #d5702c;

//WIDTH
$max-width: 1114px;
$tablet: 1024px;
$phablet: 768px;
$phone: 436px;

//BORDER RADIUS
$default-border-radius: 3px;
$rounded-border-radius: 6px;

//ANIMATION
$horizontal-distance: 100px;
$vertical-distance: 50px;
$start-delay: 10; 					//ms

//default
$default-duration: 800;			//ms
$default-delay-step: 150; 	//ms

//fast
$fast-duration: 700;				//ms
$fast-delay-step: 100; 			//ms

//slow
$slow-duration: 1000;				//ms
$slow-delay-step: 200; 			//ms

//values
$blur-size: 10px;
$small-scale: 0.8;
$large-scale: 1.2;
