/*

   _____ _       _              _____  _____ _____
  / ___/| (•)   | |           / ____// ____/ ____/  v 3.1.2
 | (___ | |_  __| | ___ ____ | |    | (___| (___
  \___ \| | |/ _` |/ _ / __/ | |     \___ \ ___ \
  ____) | | | (_| |  __\__ \ | |____ ____) |___) |
 /_____/|_|_|\__,_|\___/___/  \_____/_____/_____/


This file contains styles required for the proper functionality and display
of your Slides Project.

https://designmodo.com/slides/

*/

//CSS RESET
@import 'reset';

//MIXNIS
@import 'mixins';

//VARIABLES
@import 'variables';

//FRAMEWORK
@import 'framework';
@import 'framework_custom';

//TYPOGRAPHY
@import 'typography';
@import 'typography_custom';

//COLORS
@import 'colors';

//FLEX GRID
@import 'grid';

//FLEX GRID
@import 'flex';

//DIALOG WINDOW
@import 'dialog';

//SLIDES LAYOUT
@import 'layout';
@import 'layout_custom';

//USEFUL CLASSES
@import 'useful-classes';

//RESPONSIVENESS
@import 'responsiveness';

//BACKGROUNDS
@import 'backgrounds';
