// *** Variables ***
@import "plumber";
@import "variables";
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css");

@font-face {
	font-family: 'Venera';
	src: url('../fonts/Venera-700.otf') format('opentype');
}

@font-face {
	font-family: 'Kameron-Regular';
	src: url('../fonts/Kameron-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Kameron-Bold';
	src: url('../fonts/Kameron-Bold.ttf') format('truetype');
}

.kameron-regular {
	font-family: 'Kameron-Regular', sans-serif;
}

.brand {
	font-family: 'Venera', sans-serif;

	@include media($phone, max) {
		font-size: 7vw;
	}

}

.nunito {
	font-family: 'Nunito', sans-serif;
}

.material-icons, .fas {
	font-size: $body-font-size;
}

.text-shadow {
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}


$i: 8;
@while $i < 103 {
	.font#{$i}{
		font-size:1px * $i;
		//line-height:1px * $i +10px;
	}
	$i: $i + 2;
}

$i: 0;
@while $i < 1001 {
	.top#{$i} {margin-top: 1px * $i;}
	.bottom#{$i} {margin-bottom: 1px * $i;}
	.right#{$i} {margin-right: 1px * $i;}
	.left#{$i} {margin-left: 1px * $i;}
	.sides#{$i} {
		margin-left: 1px * $i;
		margin-right: 1px * $i;
	}
	.padding_top#{$i} {padding-top: 1px * $i;}
	.padding_bottom#{$i} {padding-bottom: 1px * $i;}
	.padding_right#{$i} {padding-right: 1px * $i;}
	.padding_left#{$i} {padding-left: 1px * $i;}
	.padding_sides#{$i} {
		padding-left: 1px * $i;
		padding-right: 1px * $i;
	}
	$i: $i + 5;
}

$i: 12;
@while $i < 202 {
	.line_height#{$i}{
		line-height:1px * $i;
	}
	$i: $i + 1;
}







// HEADINGS WITH BORDER ACCENT

/*.h1:not( .ipsType_center ):not( .h1--noLine ):after {
    content: '';
    width: 60px;
    height: 0;
    border-top: 1px solid #963c8a;
    position: absolute;
    bottom: -5px;
    left: 0;
}*/

.accent:after {
	background: none repeat scroll 0 0 $orange;
	bottom: -2px;
	content: "";
	display: block;
	height: 4px;
	position: relative;
	width: 20%;

}

.accent_center:after {
	background: none repeat scroll 0 0 $orange;
	bottom: -2px;
	content: "";
	display: block;
	height: 4px;
	position: relative;
	width: 20%;
	left: 40%;
}

/*.accent_center:after {
	content: '';
	width: 20%;
	height: 0;
	border-top: 4px solid $orange;
	position: absolute;
	bottom: -7px;
	left: 40%;
}*/







// LIST WITH TICKS

.list_ticks {
	list-style: none;
	font-size: $body-font-size * 1;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	color: white;
	line-height: $line-height * 1.1;

	@include media($phone, max) {
		font-size: $body-font-size *1.1;
		line-height: $line-height;
	}

}

.list_ticks li {
	position: relative;
	//padding-left: 2em;
	margin-bottom: 8px;
	line-height: $line-height;
	list-style-type: none;
}

.list_ticks li:before {
	//position: absolute;
	//top: 0;
	//left: 0;
	font-family: 'FontAwesome';
	color: white;
	border-radius: 24px;
	padding: 2px;
	margin-right: 0.5em;
	background: $orange;
	box-shadow: 0px 0px 0px 3px rgba(186, 204, 221, 0.5);
	font-weight: 900;
	content: "\f00c";
}


// LISTS

ol {
	padding-left: 40px;

	li {
		list-style: decimal;
		line-height: $line-height;
	}

	li:last-child {
		margin-bottom: $bottom-text-spacing;
	}

}

ul.disc {
	padding-left: 40px;

	li {
		list-style-type: disc;
		line-height: $line-height;
	}

	li:last-child {
		margin-bottom: $bottom-text-spacing;
	}

}



//heading
h1, .h1 {
	font-size: $body-font-size * 2.25;
	line-height: 1.05;
	font-family: $header-font;
	font-weight: 300;
	margin-bottom: 20px;
	letter-spacing: -0.036em;

	&.big {
		font-size: 70px;
		line-height: 1;
	}

	&.huge {
		font-size: 100px;
		line-height: 1;
		letter-spacing: -0.04em;
		margin-bottom: 40px;
	}

	&.small {
		font-size: 46px;
	}

	&.micro {
		font-size: 34px;
	}
}


h1, h2 {
	line-height: $line-height * 0.85;
	letter-spacing: 0.5px;

	@include media($phone, max) {
		font-size: 9vw;
	}

}

h3, h4, h5, h6 {
	font-family: $header-secondary-font;
	line-height: $line-height * .95;
	letter-spacing: 1px;

}

//paragraph
p,
.p {
	font-size: $body-font-size * 1;
	line-height: $line-height;
	margin-bottom: $bottom-text-spacing;
	opacity: 1;

	@include media($phone, max) {
		font-size: $body-font-size *1.1;
		line-height: $line-height;
	}

	&.hero {
		font-size: $body-font-size * 1.2;
		line-height: 1.55;
		//letter-spacing: -1px;
		font-weight: 300;
		//margin-bottom: 25px;
	}

	&.large {
		font-size: $body-font-size + 5;
		line-height: 1.4;
	}


	&.small{
		font-size: $body-font-size * 1.05;
		margin-bottom: 20px;
		line-height: $line-height;
	}

	&.tiny {
		font-size: $body-font-size * 0.75;
		line-height: 1.29441;
		margin-bottom: 20px;
	}

	&.micro {
		font-size: $body-font-size * 0.65;
		line-height: 1.22727273;
		margin-bottom: 20px;
		margin-top: 2px;
	}

	&:not(.hero) + p {
		//margin-top: -20px;
	}

	+ h2,
	+ .h2 {
		margin-top: 60px;
	}
}

p:last-child {
	margin: 0;
}

small {
	font-size: $body-font-size * 1.05;
	line-height: $line-height;
	color: $color-medium;

	.small {
		font-size: $body-font-size * 1.05;
		line-height: $line-height;

		color: $color-medium;
	}
}