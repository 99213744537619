/* Re-usable stuff */
// tables
.table { display: table; width: 100%; height: 100%; }
.cell { display: table-cell; text-align: center; vertical-align: middle; }

// overflow
.scroll-x { overflow-x: scroll;}
.scroll-y { overflow-y: scroll;}
.overflowHidden, .scrollHidden { overflow: hidden;}

//fonts
.italic { font-style: italic !important;}
.ultraLight,
.uppercase.ultraLight { font-weight: 100 !important;}
.light,
.uppercase.light { font-weight: 300 !important;}
.normal,
.uppercase.normal { font-weight: normal !important;}
.semiBold,
.uppercase.semiBold { font-weight: 500 !important;}
.bold { font-weight: 600 !important;}
.ultraBold,
.uppercase.ultraBold { font-weight: 900 !important;}

//position
.top { vertical-align: top !important;}
.left { text-align: left !important;}
.right { text-align: right !important;}
.center { text-align: center !important;}
.middle { vertical-align: middle !important;}
.bottom { vertical-align: bottom !important;}
.rtl { direction: rtl; unicode-bidi: embed; }
.rectangular { border-radius: 0px !important; }

//specific
.block { display: block !important;}
.inlineBlock { display: inline-block !important;}
.inline { display: inline !important;}
.relative { position: relative !important;}
.absolute { position: absolute !important;}
.fixed { position: fixed !important;}
.wide { width: 100% !important;}
.nowrap { white-space: nowrap;}

//opacity
.opacity-0 { opacity: 0 !important;}
.opacity-1 { opacity: 0.1 !important;}
.opacity-2 { opacity: 0.2 !important;}
.opacity-3 { opacity: 0.3 !important;}
.opacity-4 { opacity: 0.4 !important;}
.opacity-5 { opacity: 0.5 !important;}
.opacity-6 { opacity: 0.6 !important;}
.opacity-7 { opacity: 0.7 !important;}
.opacity-8 { opacity: 0.8 !important;}
.opacity-9 { opacity: 0.9 !important;}
.opacity-10,
.opaque    { opacity: 1 !important;}

.transparent { background: transparent !important }

//Padding and Margin Generation
$properties: padding padding-top padding-right padding-bottom padding-left margin margin-top margin-right margin-bottom margin-left;
@each $property in $properties {
  $i: index($properties, $property);

  @for $n from 0 through 20 {
    $value: 10px * $n;
    .#{$property}-#{$n} {
      #{$property}: $value !important;
    }
  }
}

// //Padding and Margin for Tablet
// @include media($tablet, max){
//   @each $property in $properties {
//     $i: index($properties, $property);

//     @for $n from 0 through 20 {
//       $value: 10px * $n;
//       .#{$property}-tablet-#{$n} {
//         #{$property}: $value !important;
//       }
//     }
//   }
// }

// //Padding and Margin for Phablet
// @include media($phablet, max){
//   @each $property in $properties {
//     $i: index($properties, $property);

//     @for $n from 0 through 20 {
//       $value: 10px * $n;
//       .#{$property}-phablet-#{$n} {
//         #{$property}: $value !important;
//       }
//     }
//   }
// }

// //Padding and Margin for Phone
// @include media($phone, max){
//   @each $property in $properties {
//     $i: index($properties, $property);

//     @for $n from 0 through 20 {
//       $value: 10px * $n;
//       .#{$property}-phone-#{$n} {
//         #{$property}: $value !important;
//       }
//     }
//   }
// }

// Crop
.crop { margin: 0 !important;}
.cropBottom { margin-bottom: 0 !important;}
.cropTop { margin-top: 0 !important;}
.cropLeft, .cropSides { margin-left: 0 !important;}
.cropRight, .cropSides { margin-right: 0 !important;}

.pointer-events { pointer-events: all !important; }
.disable-pointer-events { pointer-events: none !important; }

//
.space { padding: 0 20px;}
.pointer,
.cursorPointer { cursor: pointer;}
.cursorZoomIn {
	cursor:  pointer;
	cursor:  -moz-zoom-in !important;
	cursor:  -webkit-zoom-in !important;
}
.cursorZoomOut {
	cursor:  pointer;
	cursor:  -moz-zoom-out !important;
	cursor:  -webkit-zoom-out !important;
}
.cursorGrab {
	cursor: move; 
	cursor: grab !important;
	cursor: -moz-grab !important;
	cursor: -webkit-grab !important;
}

.cursorGrab:active {
	cursor: grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: -webkit-grabbing !important;
}


.disableClick { pointer-events: none;}
.cover { background-size: cover !important;}
.noSelect,.noSelect *, .disableSelect, .disableSelect * { -webkit-touch-callout: none!important; user-select: none!important;}
.selectable,.selectable *, .enableSelect, .enableSelect * { -webkit-touch-callout: auto!important; user-select: auto!important;}
.clearBoth:after { content: ""; clear: both; display: table;}