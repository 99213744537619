/* Smaller than 1200px */
@media(max-width:600px){
	
	.split {
		width: 0%;
		}
	
  $i: 12;
  @while $i < 103 {
  	.font#{$i}{
  			font-size: 0.16vw * $i;
  			//line-height: 1px * $i;
  			word-wrap:break-word;
  		}
  		$i: $i + 1;
  }
}
