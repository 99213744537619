@import "variables";

.clearfix {
  overflow: auto;
}

.slides .panel.top {
  background-color: rgba(255,255,255,0.9) !important;
  box-shadow: 0 1px 1px rgba(0,0,0,0.07);
  color: rgba(28,83,121,1);
  font-weight: bold;
}

.whiteSlide_50[class*="fade"] .content {
  background: rgba(255,255,255,0.7);
}

.slide {
  &.whiteSlide_50 {
    color: $default-dark-color;
    //background: #fff;
  }
  &.whiteSlide {
    color: $default-dark-color;
    //background: #fff;
  }

  a {
    color: $orange;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

.opacity{
  &0{
    opacity:0;
  }
  &1{
    opacity:0.1;
  }
  &2{
    opacity:0.2;
  }
  &3{
    opacity:0.3;
  }
  &4{
    opacity:0.4;
  }
  &5{
    opacity:0.5;
  }
  &6{
    opacity:0.6;
  }
  &7{
    opacity:0.7;
  }
  &8{
    opacity:0.8;
  }
  &9{
    opacity:0.9;
  }
  &10{
    opacity:1;
  }
}

.container {
  display: table;
  table-layout: fixed;
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 320px) and (max-width: 568px)  {
    .wrap {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      overflow:  hidden;

      &:not(.noSpaces),
      &.spaceWrap,
      .spaceWrap {
        padding: calc(5.56% + 50px) calc(2% + 5px);
      }
    }
  }


}

.split {
  width: 30%;
}

/***
 *      __  __
 *     |  \/  |     ( )
 *     | \  / | __ _ _ _ __
 *     | |\/| |/ _` | | '_ \
 *     | |  | | (_| | | | | |
 *     |_|  |_|\__,_|_|_| |_|
 *
 *
 */



/***
*      _____
*     |  __ \
*     | |__) __  _ __  _   _ _ __
*     |  ___/ _ \| '_ \| | | | '_ \
*     | |  | (_) | |_) | |_| | |_) |
*     |_|   \___/| .__/ \__,_| .__/
*                | |         | |
*                |_|         |_|
*/

.popup {
  &:not([class*="fade"]){
    //background: rgba(42, 90, 109, 0.9);
    background: linear-gradient(45deg, rgba(42, 90, 109, .95), rgba(0,123,168,.95));
  }
}

.popup.commercial {
  &:not([class*="fade"]){
    background-image: linear-gradient(45deg, #485461f5 1%, #28313bf5 74%);
  }
}

/***
*      _____                 _
*     |  __ \               | |
*     | |__) __ _ _ __   ___| |____
*     |  ___/ _` | '_ \ / _ | / __/
*     | |  | (_| | | | |  __| \__ \
*     |_|   \__,_|_| |_|\___|_/___/
*
*
*/

.panel {
  .sections {
    svg {
      fill: $brand-default-color;
      width: 22px;
      height: 22px;
    }

    .slides.whiteSlide & svg {
      fill: $brand-default-color;
    }

    /**
    * White icon when in mobile view
     */
    .slides.whiteSlide & svg.white-icon {
      fill: #fff;
    }

    .slides & svg.white-icon {
      fill: #fff;
    }


    .slides.whiteSlide_50 & {
      color: $default-dark-color;
    }
  }

  .slides.whiteSlide_50 & {
    //color: $default-dark-color;
  }

  .sections.footer {
    svg {
      fill: $brand-default-color;
      width: 22px;
      height: 22px;
    }

    .slides.whiteSlide & svg {
      fill: $brand-default-color;
    }

    .sidebarTrigger > svg {
      fill: #fff;
    }

    .slides.whiteSlide_50 & {
      color: $default-dark-color;
    }
  }
}

body > nav.panel.top {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 200;
  text-align: center;
  font-size: 22px;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  user-select: none;
}

/**
* DROP DOWN MENU
**/

nav > .panel {
  padding: 10px 20px;
}

.panel .sections .menu li {
  font-size: 16px;
}

.panel .menu {
  cursor:pointer;
}

.panel .menu > li {
  outline: none;
  border-radius: 50px;
  padding: 7px 7px;
  will-change: background;
  -webkit-transition:0.25s background;
  transition:0.25s background;
}

.panel .menu > li:focus,
.panel .menu > li:hover {
  background: rgba(0, 0, 0, 0.15);
}

.panel .menu > li.active {
  background-color: $brand-secondary-color;
  color: #fff;
  //background: rgba(0, 0, 0, 0.15);
}

.panel .menu > li .material-icons {
  vertical-align: bottom;
  position: relative;
  top: 2px;
  margin-top: -4px;
}






.panel .sections .menu-icons li {
  font-size: 16px;
}

.panel .menu-icons > li {
  outline: none;
  display: inline-block;
  border-radius: 50px;
  padding: 7px 7px;
}

.panel .menu-icons > li:focus,
.panel .menu-icons > li:hover {
  //background: rgba(0, 0, 0, 0.15);
}

.panel .menu-icons > li.active {
  background-color: $brand-secondary-color;
  color: #fff;
  //background: rgba(0, 0, 0, 0.15);
}

.panel .menu-icons > li .material-icons {
  vertical-align: bottom;
  position: relative;
  top: 2px;
  margin-top: -4px;
}

.dropdown.customDropdown { padding:10px 0; width: 200px;}
.dropdown.customDropdown .menu > li:not(.link),
.dropdown.customDropdown .menu > li.link a { font-size: 15px; padding: 8px 10px 8px 20px; cursor: pointer; display: block; text-align: left; }
.dropdown.customDropdown .menu > li:hover { background:rgba(0, 0, 0, 0.05);}
.dropdown.customDropdown .material-icons {
  position: relative;
  top: -3px;
  float: right;
}

.pill.controller {
  font-size: 0;
  border: 1px solid;
  display: inline-block;
  border-radius: 6px;
}

.pill.controller li {
  padding: 10px 20px;
  display: inline-block;
  border: 1px solid #007ba8;
  font-size: initial;
}

.pill.controller li:first-child {
  border-radius: 4px 0 0 4px;
}

.pill.controller li:last-child {
  border-radius: 0 4px 4px 0;
}

.pill.controller li.selected {
  background: #007ba8;
  color: #fff;
}

/***
*       _____       _
*      / ___/( )   | |
*     | (___  _  __| | ___
*      \___ \| |/ _` |/ _ \
*      ____) | | (_| |  __/
*     /_____/|_|\__,_|\___/
*
*
*/

.navigation {

  li {
    fill: #fff;
    display: block;
    background: transparent;
    padding: 9px;
    margin: 0 -9px;
    cursor: pointer;
    position: relative;
  }

  li:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 7px;
    opacity: 0.5;
    background: #fff;
    box-shadow: 0 0 1px #303030;
    transition: opacity 0.25s, background 0.25s;
  }

  li[data-title]:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 1px;
    background: rgba(40,40,40, 0.7);
    color: #fff;
    font-size: 15px;
    border-radius: 50px;
    padding: 6px 14px 7px;
    visibility: hidden;
    white-space: nowrap;
    letter-spacing: 0.01em;
    transform: translateX(-100%);
    box-shadow: 0 0 0 1px rgba(255,255,255,0.1);
  }

  li:hover:before {
    visibility: visible;
  }

  .left & li:before {
    transform: translateX(100%);
    left: inherit;
    right: 0;
  }

  .panel.bottom & li:before {
    transform: translateY(-100%) translateX(-50%);
    left: 14px;
    top: -2px;
  }

  /* navigation styles */

  /* nav colors for white slide */

  .slides.whiteSlide_50 & li:after {
    background: $default-dark-color;
    box-shadow: 0 0 1px #fff;
  }

  .slides.whiteSlide & li:after {
    background: $default-dark-color !important;
    box-shadow: 0 0 1px #fff;
  }

}

/***
*       _____ _       _
*      / ___/| ( )   | |
*     | (___ | |_  __| | ________
*      \___ \| | |/ _` |/ , / __/
*      ____) | | | (_| |  __\__ \
*     /_____/|_|_|\__,_|\___/___/
*
*
*/

// p and li font colour
.slide {
  &.whiteSlide_50 {
    color: $color-medium;

    .list_ticks li {
      color: $color-medium !important;
      opacity: 1;
    }
  }

  &.whiteSlide {
    color: $color-medium;

    .list_ticks li {
      color: $color-medium !important;
      opacity: 1;
    }
  }

  ul.list_ticks {
    color: $default-white-color;
    opacity: 1;
  }
}

/***
*
*
* BUTTONS
*
*
*
*/
.button {

  &.stroke {
    &.xsmall {
      border: 1px solid;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &:hover {
      box-shadow: inset 0 -100px 0 rgba(41,41,41,0.05), 0 4px 6px 0 rgba(0,0,0,.1),0 1px 10px 0 rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.1);
    }
  }

  &.xsmall {
    font-size: 14px;
    font-weight: 400;
    padding: 9px 14px 9px;

    .material-icons {
      vertical-align: bottom;
      position: relative;
      font-size: 20px;
      margin-right: 8px;
      bottom: 1px;

      &.right {
        margin-left: 8px;
        margin-right: 0px;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      bottom: 2px;
    }

    &.round {
      padding-left: 10px;
      padding-right: 10px;
    }

    &.rounded {
      border-radius: 5px;
    }
  }
}







/* forms */
form {
  input[type="text"],
  input[type="name"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  textarea,
  select {
    font-weight: 500;
  }
}