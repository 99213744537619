.grid.grid-74 {
	a {
		color: $default-dark-color;
		text-decoration: none;
	}
}

.flex-container {
	display: flex;
	justify-content: center;
	background-color: DodgerBlue;
}

.flex-container > div {
	background-color: #f1f1f1;
	//width: 100px;
	margin: 10px;
	text-align: center;
	line-height: 75px;
	font-size: 30px;
}

.grid-64-custom-homepage {
	padding-top: 15px;
	justify-content: center;

	.material-icons, .fas {
		margin-bottom: 15px;
		margin-top: 10px;
		font-size: $body-font-size * 3;
		opacity: 1;
		color: rgba($orange, 1);
	}

	>li:not(:last-child) {
		margin-bottom: 20px;
		border-right: 1px solid rgba($default-dark-color, .5);
	}

	a {
		text-decoration: none;
	}

	.cell-64 {
		padding: 5px 10px;
		position: relative;
		display: block;
		transition: 0.25s;
		//border-top: 5px solid transparent;
		//background: rgba(255, 255, 255, .1);
		color: rgba($default-dark-color, 1);

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		.button {
			&.stroke {
				border-color: $brand-secondary-color;
			}
			color: $brand-secondary-color;
		}

		&:hover {
			.material-icons, .fas {
				opacity: 1;
				transition-timing-function: ease-in-out;
				transition: 0.5s;
				color: $orange;
			}

			background-color: rgba(#fff, 1);
			//border-top: 5px solid $orange;
			transition-timing-function: ease-in-out;
			transition: 0.5s;
			color: $default-dark-color;
		}

		@at-root .slide.whiteSlide & {
			border-top: 5px solid transparent;
			background: rgba(#ffffff,0.7);
			color: $brand-default-color;
			box-shadow: 0 2px 10px rgba(0,0,0,0.2),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}

		@at-root .slide.whiteSlide &:hover {
			background: #fff;
			border-top: 5px solid $orange;
			transition-timing-function: ease-in-out;
			transition: 0.5s;
			color: $brand-default-color;
			box-shadow: 0 4px 20px rgba(0,0,0,0.4),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}
	}

	.cell-64-popup {
		padding: 20px 30px;
		position: relative;
		display: block;
		transition: 0.25s;
		border: 1px solid rgba(#202020,0.3);
		color: #222;
		background: rgba(#ffffff,0.85);

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		&:hover {
			background: #fff;
			color: $default-dark-color;
			opacity: 1;
			//box-shadow: $large-shadow;
			border-color: transparent !important;
		}

		@at-root .slide.whiteSlide & {
			border: 1px solid rgba(#202020,0.1);
			background: rgba(#ffffff,0.6);
		}

		@at-root .slide.whiteSlide &:hover {
			background: #fff;
		}
	}
}

.grid-64-custom-homepage2 {
	padding-top: 15px;
	justify-content: center;

	.material-icons, .fas {
		margin-bottom: 15px;
		margin-top: 10px;
		font-size: $body-font-size * 3;
		opacity: 1;
		color: rgba($orange, 1);
	}

	>li:not(:last-child) {
		margin-bottom: 20px;
		border-right: 1px solid rgba($default-white-color, .0);
	}

	a {
		text-decoration: none;
	}

	.cell-64 {
		padding: 5px 10px;
		position: relative;
		display: block;
		transition: 0.25s;
		//border-top: 5px solid transparent;
		//background: rgba($default-dark-color, .7);
		background: rgba($default-white-color, .8);
		color: rgba($default-dark-color, 1);
		//text-shadow: 1px 1px rgba($default-dark-color, .5);

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		.button {
			&.stroke {
				border-color: $brand-secondary-color;
			}
			color: $default-dark-color;
			background-color: $default-white-color;
		}

		&:hover {
			.material-icons, .fas {
				opacity: 1;
				transition-timing-function: ease-in-out;
				transition: 0.5s;
				color: $orange;
			}

			.button {
				color: $default-white-color;
				background-color: $default-dark-color;
			}

			background-color: rgba(#fff, 1);
			//border-top: 5px solid $orange;
			transition-timing-function: ease-in-out;
			transition: 0.5s;
			color: $default-dark-color;
		}

		@at-root .slide.whiteSlide & {
			border-top: 5px solid transparent;
			color: $brand-default-color;
			box-shadow: 0 2px 10px rgba(0,0,0,0.2),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}

		@at-root .slide.whiteSlide &:hover {
			border-top: 5px solid $orange;
			transition-timing-function: ease-in-out;
			transition: 0.5s;
			color: $brand-default-color;
			box-shadow: 0 4px 20px rgba(0,0,0,0.4),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}
	}

	.cell-64-popup {
		padding: 20px 30px;
		position: relative;
		display: block;
		transition: 0.25s;
		border: 1px solid rgba(#202020,0.3);
		color: #222;
		background: rgba(#ffffff,0.85);

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		&:hover {
			background: #fff;
			color: $default-dark-color;
			opacity: 1;
			//box-shadow: $large-shadow;
			border-color: transparent !important;
		}

		@at-root .slide.whiteSlide & {
			border: 1px solid rgba(#202020,0.1);
			background: rgba(#ffffff,0.6);
		}

		@at-root .slide.whiteSlide &:hover {
			background: #fff;
		}
	}
}

.grid-64-custom-homepage3 {
	justify-content: center;

	a {
		text-decoration: none;
	}

	.cell-64 {
		padding: 2em;
		position: relative;
		display: block;
		background: rgba($default-white-color, .8);

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		.button {
			&.stroke {
				border-color: $brand-secondary-color;
			}
			color: $default-dark-color;
			background-color: $default-white-color;

			&:hover {
				background-color: $default-dark-color;
				color: $default-white-color;
			}
		}
	}
}

.grid-64-custom-residential {
	padding: 5px 10px;
	position: relative;
	display: block;
	transition: 0.25s;
	font-size: $body-font-size;

	.material-icons, .fas {
		margin-bottom: 15px;
		margin-top: 10px;
		font-size: $body-font-size * 3;
	}

	>li:not(:last-child) {
		margin-bottom: 20px;
		border-right: 1px solid rgba($default-dark-color, .3);

		@include media($phone, max) {
			border-right: 0px solid transparent;
		}

		@include media($tablet, max) {
			border-right: 0px solid transparent;
		}

	}

	a {
		text-decoration: none;
	}

	.cell-64 {
		padding: 5px 10px;
		position: relative;
		display: block;
		transition: 0.25s;
		border: 0px solid rgba(#202020,0);
		background: rgba(#ffffff,0);
		color: $default-dark-color;

		.material-icons, .fas {
			margin-bottom: 15px;
			margin-top: 10px;
			font-size: $body-font-size * 3;
			opacity: 1;
			color: rgba($orange, 1);
		}

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		.button {
			&.stroke {
				border-color: $brand-secondary-color;
			}
			color: $brand-secondary-color;
		}

		&:hover {
			background: #fff;
			color: $default-dark-color;
			opacity: 1;
			border-color: transparent !important;
			box-shadow: 0 10px 20px rgba(0,0,0,0.3);
		}

		@at-root .slide.whiteSlide & {
			border: 0px solid rgba(#202020,0.1);
			background: rgba(#ffffff,0);
			//box-shadow: 0 2px 10px rgba(0,0,0,0.2),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}

		@at-root .slide.whiteSlide &:hover {
			background: #fff;
			box-shadow: 0 2px 10px rgba(0,0,0,0.2),0 5px 20px rgba(0,0,0,0.07),0 0px 1px rgba(0,0,0,0.12);
		}
	}
}

.grid-64-custom-commercial {
	padding: 5px 10px;
	justify-content: center;

	a {
		text-decoration: none;
	}

	.material-icons, .fas {
		margin-bottom: 15px;
		margin-top: 10px;
		font-size: $body-font-size * 3;
		color: $orange;
	}

	>li:not(:last-child) {
		margin-bottom: 20px;
		border-right: 1px solid rgba($default-white-color, .3);

		@include media($phone, max) {
			border-right: 0px solid transparent;
		}

		@include media($tablet, max) {
			border-right: 0px solid transparent;
		}

	}

	.cell-64 {
		padding: 5px 10px;
		position: relative;
		display: block;
		transition: 0.25s;
		//border: 1px solid rgba(#ffffff,0.5);
		background: rgba(#fff,0);
		color: $default-white-color;

		.button {
			&.stroke {
			border-color: #fff;
			}
			color: #fff;
		}

		&.rounded {
			border-radius: $rounded-border-radius;
		}

		&:hover {
			background: rgba(#fff,0.9);
			color: $default-dark-color;
			opacity: 1;
			//box-shadow: $large-shadow;
			border-color: transparent !important;

			.button {
				&.stroke {
					border-color: $default-dark-color;
				}
				color: $default-dark-color;
			}
		}

		@at-root .slide.whiteSlide & {
			border: 1px solid rgba(#202020,0.1);
			background: rgba(#ffffff,0.85);
		}

		@at-root .slide.whiteSlide &:hover {
			background: #fff;
		}
	}
}


.header-64 {
	mark {
		color: #fff;
		background-color: rgba($brand-default-color, .75);

	}
}









.showcase .showcase-container li {
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.fix-custom {
	padding-left: calc(8% + 15px);
	padding-right: calc(8% + 15px);
}

@media screen and (min-width: 1024px) and (max-width: 1698px)  {
	.fix-custom {
		padding-left: 0%;
		padding-right: 0%;
		max-width: 1800px;
		left: -50px;
		width: calc(100% + 100px) !important;
		position: relative;
	}
	.showcase .showcase-container li:nth-child(3n+1) {
		//text-align: right;
	}
}

.showcase li .sc {
	margin-bottom: 50px;
	color: #858585;
	font-size: $body-font-size;
	line-height: $line-height;
	position: relative;
}

.showcase li .sc-img {
	font-size: 0;
	line-height: 0;
	background: $brand-secondary-color;
	padding-top: 20px;
	border-radius: 5px;
	display: inline-block;
	box-shadow: 0 12px 24px rgba(0,0,0,0.4);
	-webkit-transition: 1s cubic-bezier(0.35, 1.69, 0.35, 0.81);
	transition: 1s cubic-bezier(0.35, 1.69, 0.35, 0.81);
}

.showcase.slide li .sc:hover .sc-img {
	box-shadow: 0 22px 34px rgba(0,0,0,0.4);  box-shadow: 13px 22px 34px rgba(0,0,0,0.4);
	-webkit-transform: translateY(-10px) scale(1.025);
	transform: translateY(-10px) scale(1.025);
}

/* shiny effect on hover */
.showcase.slide li .sc-img:after {
	content: ":";
	position: absolute;
	top: 20px;
	left: 0;
	bottom: -1px;
	right: 0;
	width: 100%;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
	background: -moz-linear-gradient(-45deg,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	opacity: 0;
	-webkit-transition: 1s cubic-bezier(0.35, 1.69, 0.35, 0.81);
	transition: 1s cubic-bezier(0.35, 1.69, 0.35, 0.81);
	border-radius: 0 0 3px 3px;
}

.showcase.slide li .sc:hover .sc-img:after {
	opacity: 0.5;
}

.showcase.popup li .sc-img {
	background: #35363a;
	padding-top: 30px;
}

.showcase li .sc-img img {
	margin-bottom: -1px;
	border-radius: 0 0 3px 3px;
}

.showcase.popup li .sc-img a:hover {
	opacity: 1;
}

.showcase.popup li .sc-img a:before {
	content: 'remove_red_eye';
	background: rgba(0,0,0,0.2);
	top: 30px;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	-webkit-transition: 0.25s;
	transition: 0.35s;
}

.showcase.popup li .sc-img:hover a:before {
	opacity: 0;
}

.showcase li .sc-img:before {
	content: "...";
	position: absolute;
	font-size: 45px;
	color: #fff;
	top: -2px;
	line-height: 0;
	letter-spacing: -2px;
	left: 4px;
}

/* popup */
.showcase.popup li .sc-img:before {
	font-size: 50px;
	top: 0px;
	left: 9px;
}

.showcase li .sc strong {
	display: block;
	color: #202020;
	margin-top: 10px;
}

.slide.showcase li .sc:after {
	content: attr(data-hover-text);
	position: absolute;
	top: calc(50% - 50px);
	left: 50%;
	right: inherit;
	background: #fff;
	border-radius: 50px;
	padding: 8px 21px;
	opacity: 0;
	-webkit-transform: translateY(-10px) scale(1.025) translateX(-50%);
	transform: translateY(-10px) scale(1.025) translateX(-50%);
	-webkit-transition: 1s cubic-bezier(0.35,1.69,0.35,0.81);
	transition: 1s cubic-bezier(0.35,1.69,0.35,0.81);
}

.slide.showcase li .sc:hover:after {
	opacity: 1;
	-webkit-transform: translateY(0px) translateX(-50%);
	transform: translateY(0px) translateX(-50%);
}

.showcase-container {
	align-items: center;
	justify-content: center;
}

.showcase-container.controller li.selected {
	cursor: pointer;
	pointer-events: all;
}

.popup[data-popup-id="showcase"] .slider > li.selected {
	-webkit-animation: 0.5s fadeIn;
	animation: 0.5s fadeIn;
}

.fix-custom-popup {
	max-width: 1480px !important;
}

form label.error {
	color: #F44336;
	font-size: 16px;
	margin-bottom: 10px;
	font-weight: bold;
}

form input.error, form input.error:focus {
	box-shadow: 0 0 0 1px #F44336 inset !important;
}