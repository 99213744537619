/* Welcome new Grid! */
.flex {
  display:flex;
  flex-wrap:wrap;
  margin-left:-2%;
  margin-right:-2%;

  /* default spaces */
  & >[class*='col-'] {
    padding-left:2%;
    padding-right:2%;
  }

  &.fixedSpaces {
    $gapSpace: 14px;

    margin-left: -$gapSpace;
    margin-right: -$gapSpace;

    >[class*='col-'] {
      padding-left: $gapSpace;
      padding-right: $gapSpace;
    }
  }

  &>.noSpaces {
    margin-left:0;
    margin-right:0;

    >[class*='col-'] {
      padding-left:0;
      padding-right:0;
    }
  }

  /* alignments */
  &.verticalCenter {
    align-items: center;  
  }

  &.reverse {
    flex-wrap:wrap-reverse;
  }

  &.bottom [class*='col-'],
  &.reverse.top [class*='col-']{
    align-self: flex-end;
  }
    
  &.top [class*='col-'],
  &.reverse.bottom [class*='col-'] {
    align-self: flex-start;
  }

  // generate colums
  // .col-1-12 { width: 8.33333%; }
  @for $i from 1 through 12 {
    $width: (100% / 12) * $i;

    > .col-#{$i}-12 {
      width: $width;
    }
  }

  // generate colums
  // .col-1-10 { width: 10%; }
  @for $i from 1 through 10 {
    $width: (100% / 10) * $i;

    > .col-#{$i}-10 {
      width: $width;
    }
  }
}

/* ipad landscape and > */
@include media($tablet, "max") {
  .flex:not(.later) > [class*='col-']:not([class*='col-phablet-']):not([class*='col-phone-']) { width: 100%; }
  .flex > .col-tablet-1-1 { width: 100% !important; }
  .flex > .col-tablet-1-2 { width: 50% !important; }
  .flex > .col-tablet-1-3 { width: 33.33333333% !important; }
  .flex > .col-tablet-2-3 { width: 66.66666666% !important; }
  .flex > .col-tablet-1-4 { width: 25% !important; }
  .flex > .col-tablet-3-4 { width: 75% !important; }
  .flex > .col-tablet-1-5 { width: 20% !important; }
  .flex > .col-tablet-2-5 { width: 40% !important; }
  .flex > .col-tablet-3-5 { width: 60% !important; }
  .flex > .col-tablet-4-5 { width: 80% !important; }
}

/* phablet landscape and ipad portrait and > */
@include media($phablet, "max") {
  .flex.later > [class*='col-']:not([class*='col-phone-']) { width: 100%; }
  .flex > .col-phablet-1-1 { width: 100% !important; }
  .flex > .col-phablet-1-2 { width: 50% !important; }
  .flex > .col-phablet-1-3 { width: 33.33333333% !important; }
  .flex > .col-phablet-2-3 { width: 66.66666666% !important; }
  .flex > .col-phablet-1-4 { width: 25% !important; }
  .flex > .col-phablet-3-4 { width: 75% !important; }
}

/* phone portrait and > */
@include media($phone, "max") {
  .flex > [class*='col-'] { width: 100%; }
  .flex > .col-phone-1-1 { width: 100% !important; }
  .flex > .col-phone-1-2 { width: 50% !important; }
  .flex > .col-phone-1-3 { width: 33.33333333% !important; }
  .flex > .col-phone-2-3 { width: 66.66666666% !important; }
}